import React, { useState, Suspense, lazy, useEffect } from 'react';
import './App.css';
import { ThemeProvider } from '@material-ui/core';
import { theme } from './assets/universal_styles';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { RenderPage } from './components/RenderPage';
import CouponPortal from './views/CouponPortal';
import ReactGA from 'react-ga';
import FacebookPixel from 'react-facebook-pixel';

const DiscountPaywall = lazy(() => import('./views/DiscountPaywall'));
const Paywall = lazy(() => import('./views/Paywall'));
const ThankYou = lazy(() => import('./views/ThankYou'));
const Signup = lazy(() => import('./views/SignUp'));
const DiscountSignup = lazy(() => import('./views/DiscountSignup'));
const AppOrWeb = lazy(() => import('./views/AppOrWeb'));

function Onboard() {
  ReactGA.initialize('UA-97118261-2');
  FacebookPixel.init('261779754537213');
  // other pixel id - 3535988599832780
  console.log('v1.3 - Adding FB analytics');

  const [userEmail, setUserEmail] = useState<string | undefined>();
  const [userCreds, setUserCreds] = useState({ email: '', password: '' });

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    FacebookPixel.pageView();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Suspense fallback=''>
          <Switch>
            <Route path='/signup'>
              <Signup setUserEmail={setUserEmail} setUserCreds={setUserCreds} />
            </Route>
            <Route path='/signup_success'>
              <Paywall userEmail={userEmail} />
            </Route>
            <Route path='/app_or_web'>
              <AppOrWeb email={userCreds.email} password={userCreds.password} />
            </Route>
            <Route path='/Thank_You'>
              <ThankYou />
            </Route>
            <Route path='/Thank_You_discount'>
              <ThankYou discount={true} />
            </Route>
            <Route path='/promocode'>
              <CouponPortal setUserEmail={setUserEmail} />
            </Route>
            <Route path='/coupon_success'>
              <ThankYou coupon={true} />
            </Route>
            <Route exact path='/clearerthinking'>
              <DiscountSignup setUserEmail={setUserEmail} />
            </Route>
            <Route exact path='/discount'>
              <DiscountSignup
                discountLogin={true}
                setUserEmail={setUserEmail}
              />
            </Route>
            <Route path='/discount/subscribe'>
              <DiscountPaywall userEmail={userEmail} />
            </Route>
            {/* Why do I have a second switch here? */}
            <Switch>
              <Route exact path='/:page?'>
                <RenderPage setEmail={setUserEmail} />
              </Route>
            </Switch>
          </Switch>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default Onboard;
