import { makeStyles, styled, Typography } from '@material-ui/core';
import { theme } from './universal_styles';

// ! I think this backgroundImage property (and perhaps this whole class) is useless - it's being set inline, in DiscountPaywall.tsx.
const CheckoutStyles = makeStyles({
  checkoutBackground: {
    backgroundImage: 'url(./assets/MountainBackground.png)',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    top: '0',
    backgroundPositionX: '80%',
    height: '100vh',
  },
});

const PaywallStyles = makeStyles({
  card: {
    backgroundColor: '#0000007d',
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    paddingBottom: 5,
  },
});

const WhiteText = styled(Typography)({
  color: '#dadada',
});

const OnboardStyles = makeStyles({
  card: {
    backgroundColor: theme.palette.primary.main,
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'center',
    width: 310,
    paddingTop: 10,
  },
});

export { CheckoutStyles, PaywallStyles, WhiteText, OnboardStyles };
