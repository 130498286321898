import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#09a0aa',
    },
    // background: {
    //   default: '#000000ad'
    // }
  },
});

export { theme };
