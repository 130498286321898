import React, { useEffect, useState, Dispatch } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import OnboardPages from './onboard_pages';
import { RenderGrid } from './ux_components';
import ReactGA from 'react-ga';
import FacebookPixel from 'react-facebook-pixel';

interface RenderPageProps {
  setEmail: Dispatch<React.SetStateAction<undefined | string>>;
}

let GoogleButton: any;

const RenderPage = (props: RenderPageProps) => {
  let { page } = useParams<{ page: string | undefined }>();
  if (Number.isNaN(Number(page)) || Number(page) > 6) {
    page = '0';
  }

  let Page = Number(page);

  const [answers, setAnswer] = useState<string[]>([]);

  let location = useLocation();
  let history = useHistory();

  const query = new URLSearchParams(location.search);
  let path = query.get('path');

  function pageTurn(value?: string) {
    if (Page === 6) {
      return history.push('/signup');
    }
    let pageNumber = Page + 1;

    history.push(`${pageNumber}`);

    if (value) {
      let answerBucket = [...answers];
      answerBucket[pageNumber - 2] = value;
      setAnswer(answerBucket);
    }
  }

  useEffect(() => {
    ReactGA.event({
      category: 'half_web_onboard',
      action: `loaded_page_${Page}`,
    });
    FacebookPixel.trackCustom(`loaded_page_${Page}`);
    console.log('analytics effect fired');
  }, [Page]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    let isLandingPage = Page < 3;

    if (isLandingPage) {
      if (path === 'both') {
        // eslint-disable-next-line
        path = 'Depression and Anxiety';
      }

      if (typeof path === 'string') {
        setAnswer([path]);
      }
    }
  }, []);

  const pages = OnboardPages;

  let PageToRender = pages[Page];

  return (
    <RenderGrid>
      <PageToRender handleClick={pageTurn} answers={answers} />
    </RenderGrid>
  );
};

export { RenderPage, GoogleButton };
