import React, { CSSProperties } from 'react';
import {
  Box,
  BoxProps,
  Button,
  GridProps,
  styled,
  Typography,
  Grid,
  ButtonBaseProps,
} from '@material-ui/core';

const StyledText = styled(Typography)({
  color: '#2F4F4F',
  marginBottom: '2.5vh',
  maxWidth: 320,
});

/**Buttons */
interface RenderButtonProps extends ButtonBaseProps {
  onClick: (param?: any) => void;
  text: string;
  width?: string | number;
}

function RenderButton(props: RenderButtonProps) {
  let width = props.width ? props.width : '100%';

  return (
    <Button
      variant='contained'
      size='large'
      color='primary'
      style={{ width: `${width}`, maxWidth: 320, textTransform: 'none' }}
      onClick={() => props.onClick()}
    >
      {props.text}
    </Button>
  );
}

interface RenderButtonGroupProps extends ButtonBaseProps {
  options: Array<string>;
  handleClick: (answer: string) => void;
}

function RenderButtonGroup(props: RenderButtonGroupProps) {
  const buttons = props.options.map((option, index) => (
    <Button
      key={option}
      variant='contained'
      size='large'
      color='primary'
      style={{ marginBottom: 10, textTransform: 'none' }}
      onClick={() => props.handleClick(option)}
      fullWidth={true}
    >
      {option}
    </Button>
  ));

  return <div style={{ maxWidth: 420 }}>{buttons}</div>;
}

/**Images */
interface ImageProps {
  id?: string;
  alt: string;
  imageAddress: string;
  widthAndHeight?: [number, number];
  style?: CSSProperties;
}

function RenderImage(props: ImageProps) {
  const id = props.id ? props.id : '';
  const widthAndHeight = props.widthAndHeight
    ? [props.widthAndHeight[0], props.widthAndHeight[1]]
    : [100, 100];

  return (
    <img
      src={props.imageAddress}
      width={widthAndHeight[0]}
      height={widthAndHeight[1]}
      style={props.style}
      alt={props.alt}
      data-testid={id}
    />
  );
}

interface BoldTextProps extends BoxProps {
  text: string;
}

function BoldText(props: BoldTextProps) {
  return (
    <Box
      fontWeight='fontWeightBold'
      component='span'
      style={{ paddingRight: '.4em', color: 'white' }}
    >
      {props.text}
    </Box>
  );
}

function RenderGrid(props: GridProps) {
  return (
    <Grid
      container
      direction='column'
      wrap='nowrap'
      alignItems='center'
      style={{
        height: '100vh',
        minHeight: '100vh',
        padding: '5vw',
        paddingTop: '10`cvh',
      }}
    >
      {props.children}
    </Grid>
  );
}

export {
  RenderButton,
  RenderButtonGroup,
  RenderImage,
  StyledText,
  BoldText,
  RenderGrid,
};
