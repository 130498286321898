import React, { useState } from 'react';
import crypto from 'crypto';
import { theme } from '../assets/universal_styles';
import {
  TextField,
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
  Button,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { UCSResponse } from '../assets/custom_types';
import { RenderGrid } from '../components/ux_components';

// TODO: This component is far too much of a duplicate of SignUp.tsx; they should be merged, when time allows.

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
  },
});

const useStyles = makeStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1em',
  },
  textfield: {
    marginTop: '1em',
  },
});

interface SignUpProps {
  setUserEmail: (userEmail: string) => void;
}

export default function RenderCouponSignup(props: SignUpProps) {
  const classes = useStyles();
  const history = useHistory();

  const [error, setError] = useState(false);

  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log('default prevented');
    const data = new FormData(event.target);

    const authRequest = {
      name: data.get('name'),
      email: data.get('email'),
      password: data.get('password'),
    };
    const userEmail = data.get('email');
    const coupon = (data.get('coupon') as string).toLowerCase();
    props.setUserEmail(userEmail as string);

    const couponHash = crypto.createHash('sha256').update(coupon).digest('hex');

    const validCouponHashes = [
      'a1f562c09b224a139f967fd2e6f6fa40b1891664bea858d8e3e884f5d30fb6e5',
    ];

    if (!validCouponHashes.includes(couponHash)) {
      alert('Invalid coupon code; please try again.');
      return (document.getElementById('couponform') as HTMLFormElement).reset();
    }

    try {
      const response = await fetch(
        'https://upliftserver.com/api/account/register',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(authRequest),
        }
      );

      const result = (await response.json()) as UCSResponse;

      if ('token' in result) {
        console.log(result);

        const couponRequest = {
          email: userEmail,
          customerId: 'coupon',
        };

        const couponResponse = await fetch(
          'https://upliftserver.com/api/stripe',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(couponRequest),
          }
        );

        const couponResult = (await couponResponse.json()) as {
          success: boolean;
        };
        if (couponResult.success === true) {
          console.log('Coupon result true');
          history.push('/coupon_success');
        } else {
          console.log('Coupon result false');
        }
      } else {
        if ((result.error = 'Email already in use.')) {
          setError(true);
        }
        throw new Error(`${JSON.stringify(result)}`);
      }
    } catch (e) {
      console.log(`Sign-up via coupon failed. Error: ${e}`);
    }
  };

  return (
    <RenderGrid>
      <MuiThemeProvider theme={darkTheme}>
        {error && (
          <Typography color='secondary'>
            Email already in use. This page is for creating a new account.
          </Typography>
        )}
        <form
          id='couponform'
          className={classes.form}
          onSubmit={handleSubmit}
          autoComplete='off'
        >
          <TextField
            className={classes.textfield}
            name='name'
            required
            id='name'
            label='Name'
          />
          <TextField
            className={classes.textfield}
            name='email'
            required
            id='email'
            type='email'
            label='Email'
          />
          <TextField
            className={classes.textfield}
            name='password'
            // variant="filled"
            required
            id='password'
            label='Choose a password'
            type='password'
          />
          <TextField
            className={classes.textfield}
            name='coupon'
            // variant="filled"
            required
            id='coupon'
            label='Promo Code'
          />
          <MuiThemeProvider theme={theme}>
            <Button
              type='submit'
              variant='contained'
              size='large'
              style={{
                width: 320,
                textTransform: 'none',
                marginTop: '2rem',
                backgroundColor: `${theme.palette.primary.main}`,
                color: 'white',
              }}
            >
              Sign up with email
            </Button>
          </MuiThemeProvider>
        </form>
      </MuiThemeProvider>
      {/* // ! For the moment, I'm not enabling Google/Apple login on coupons. It would require additional logic on GoogleButton and AppleButton components themselves. As-is, this is just for users creating new accounts for free, so I don't think there's any problem requiring them to use email. When time allows, they could be enabled. */}
      {/* <Typography style={{ color: 'white', marginBottom: '.7em' }}>
        OR
      </Typography>
      <AppleButton />
      <GoogleButton setUserEmail={props.setUserEmail} /> */}
    </RenderGrid>
  );
}
