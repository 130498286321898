import React from 'react';
import { Card, CardActionArea, CardContent } from '@material-ui/core';
import {
  RenderImage,
  StyledText,
  RenderButton,
  RenderButtonGroup,
  BoldText,
} from '../components/ux_components';
import {
  logo,
  wordCloud,
  onboardGraph,
  sessionIcon,
  toolIcon,
  cloudAndSun,
} from '../assets/onboard_images';
import { OnboardStyles } from '../assets/onboard_styles';

interface PageProps {
  handleClick: (value?: string) => void;
  answers: string[];
}

function PageOne(props: PageProps) {
  return (
    <>
      <RenderImage
        id='logo'
        alt='UpLift Sun and Clouds Logo'
        imageAddress={logo}
        style={{ maxWidth: '8rem', marginTop: '10vh' }}
      />
      <StyledText variant='h5' style={{ color: 'black' }}>
        Welcome to UpLift
      </StyledText>
      <StyledText align='center'>
        UpLift is an evidence-based program for depression & anxiety.
      </StyledText>
      <StyledText align='center'>
        Feel happier, calmer, and more resilient with interactive sessions and
        daily tools.
      </StyledText>
      <RenderButton onClick={props.handleClick} text='Next' />
    </>
  );
}

function PageTwo(props: PageProps) {
  const options = ['Reduce Depression', 'Reduce Anxiety', 'Both'];

  return (
    <>
      <StyledText variant='h6' style={{ marginTop: '10vh', color: 'black' }}>
        What brings you to UpLift?
      </StyledText>
      <StyledText>I'd like to...</StyledText>
      <RenderButtonGroup options={options} handleClick={props.handleClick} />
    </>
  );
}

function PageThree(props: PageProps) {
  const options = ['Rarely', 'Some Days', 'Most Days', 'Every Day'];

  return (
    <>
      <img
        src={wordCloud}
        alt='Depression Word Cloud'
        style={{ marginBottom: 20 }}
      />
      <StyledText align='center'>
        On how many days each week do you feel one of the above?
      </StyledText>
      <RenderButtonGroup options={options} handleClick={props.handleClick} />
    </>
  );
}

function PageFour(props: PageProps) {
  const options = ['Higher Mood', 'More Energy', 'Less Stress'];
  const text =
    props.answers[1] === 'Rarely'
      ? "I'm glad to hear it's rare! UpLift can help you reduce them even further."
      : "I'm sorry to hear that. We can improve the way you've been feeling.";

  const depAnxDrop =
    props.answers[0] === 'Reduce Anxiety'
      ? 'anxiety levels dropped nearly 40%'
      : 'depression levels dropped 52%';

  return (
    <>
      <StyledText align='center'>{text}</StyledText>
      <StyledText align='center'>
        In an UpLift study, users' {depAnxDrop} in the first month.
      </StyledText>
      <img
        src={onboardGraph}
        width={320}
        height={276}
        alt='Graph showing depression reduction over three weeks'
        style={{ marginBottom: 20 }}
      />
      <StyledText>Tell me more about how you'd like to feel.</StyledText>
      <RenderButtonGroup options={options} handleClick={props.handleClick} />
    </>
  );
}

function PageFive(props: PageProps) {
  const options = [
    'Steady: 1 Session / 2 weeks',
    'Serious: 1 Session / Week',
    'All In: 2 Sessions / Week',
  ];

  return (
    <>
      <img
        src={sessionIcon}
        width={304}
        height={92}
        alt='Session Icon Example'
        style={{ marginBottom: 20, borderRadius: 8 }}
      />
      <StyledText align='center'>
        We can work on that! You can improve your mood by mastering effective
        Cognitive Behavioral Skills through UpLift's 11 sessions.
      </StyledText>
      <StyledText align='center'>
        In each 40-minute session, you learn empowering techniques and answer
        questions. Then, UpLift adapts to your needs and responses.
      </StyledText>
      <StyledText variant='h6' style={{ color: 'black' }}>
        Choose a Session Goal
      </StyledText>
      <RenderButtonGroup options={options} handleClick={props.handleClick} />
    </>
  );
}

function PageSix(props: PageProps) {
  const options = [
    'Steady: 5 Minutes / Day',
    'Committed: 10 Minutes / Day',
    'Serious: 15 Minutes / Day',
    'All In: 20+ Minutes / Day',
  ];

  return (
    <>
      <RenderImage imageAddress={toolIcon} alt='Example tool icon' />
      <StyledText align='center'>
        Outside of the sessions, you can hone your new skills by using daily
        tools that unlock as you complete sessions.
      </StyledText>
      <StyledText align='center'>
        Let's set a goal for working on your wellbeing each day. A higher goal
        isn't necessarily better. Choose what feels achievable to you.
      </StyledText>
      <StyledText variant='h6' align='center' style={{ color: 'black' }}>
        Pick a Daily Goal:
      </StyledText>
      <RenderButtonGroup options={options} handleClick={props.handleClick} />
    </>
  );
}

function PageSeven(props: PageProps) {
  const classes = OnboardStyles();

  let path = props.answers[0];
  switch (path) {
    case 'Reduce Depression':
      path = 'UpLift for Depression';
      break;
    case 'Reduce Anxiety':
      path = 'UpLift for Anxiety';
      break;
    case 'Both':
      path = 'Depression & Anxiety';
      break;
    case 'depression':
      path = 'UpLift for Depression';
      break;
    case 'anxiety':
      path = 'UpLift for Anxiety';
      break;
    case 'both':
      path = 'Depression & Anxiety';
      break;
    default:
      path = 'Depression & Anxiety';
  }

  const formatAnswer = (longAnswer: string) => longAnswer.split(':')[1];

  const formattedAnswers = [
    formatAnswer(`${props.answers[3]}`),
    formatAnswer(`${props.answers[4]}`),
  ];

  /**The nested-typography, and the associated styles, are necessary to render bold without a visual shift on render. If I use them elsewhere, it may be worth making a more generalizable solution. */
  return (
    <>
      <StyledText>Your responses have been saved!</StyledText>
      <StyledText align='center'>
        Here's your customized plan that supports you in improving your life.
      </StyledText>
      <Card className={classes.card} aria-label='Summary of your UpLift plan'>
        <CardActionArea>
          <RenderImage
            alt='UpLift cloud and sun'
            imageAddress={cloudAndSun}
            widthAndHeight={[230, 180]}
            style={{ marginLeft: '10%' }}
          />
          <CardContent>
            <StyledText style={{ color: 'white' }}>
              <BoldText text='Path:' />
              {path}
            </StyledText>
            <StyledText style={{ color: 'white' }}>
              <BoldText text='Your Goal:' />
              {props.answers[2]}
            </StyledText>
            <StyledText style={{ color: 'white' }}>
              <BoldText text='Session Pace:' />
              {String.prototype.trim.call(`${formattedAnswers[0]}`)}
            </StyledText>
            <StyledText
              style={{
                marginBottom: 0,
                color: 'white',
              }}
            >
              <BoldText text='Daily Pace:' />
              {String.prototype.trim.call(`${formattedAnswers[1]}`)}
            </StyledText>
          </CardContent>
        </CardActionArea>
      </Card>
      <RenderButton onClick={props.handleClick} text='Commit to my plan!' />
    </>
  );
}

let OnboardPages = [
  PageOne,
  PageTwo,
  PageThree,
  PageFour,
  PageFive,
  PageSix,
  PageSeven,
];

export default OnboardPages;
