import cloudAndSun from '../assets/onboard/cloud_and_sun.png';
import onboardGraph from '../assets/onboard/dark_uplift_graph.png';
import sessionIcon from '../assets/onboard/session3icon.png';
import toolIcon from '../assets/onboard/onboardToolIcon.png';
import wordCloud from '../assets/onboard/wordcloud.png';
import logo from '../assets/UpLift_Logo.svg';
//paywall icons
import coreBeliefHeart from './onboard/paywall/corebeliefsicon@1x.png';
import coreBeliefGuy from './onboard/paywall/smallcorebeliefsicon.png';
import goalsIcon from './onboard/paywall/smallgoalsicon.png';
import thoughtErrorsShield from './onboard/paywall/smallthoughterrorsicon.png';
import successIcon from './onboard/paywall/successesicon@1x.png';
import thoughtErrorsGears from './onboard/paywall/thoughterrorsicon.png';
//thank you screen
import darkAppStore from './onboard/App_Store_Badge_black.svg';

export {
  cloudAndSun,
  onboardGraph,
  sessionIcon,
  toolIcon,
  wordCloud,
  logo,
  coreBeliefGuy,
  coreBeliefHeart,
  goalsIcon,
  thoughtErrorsGears,
  thoughtErrorsShield,
  successIcon,
  darkAppStore,
};
